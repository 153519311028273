import Vue from 'vue'
import App from './App.vue'

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'local',
  cluster: 'mt1',
  wsHost: 'boxter.club',
  wsPort: 443,
  forceTLS: false,
  disableStats: true,
  authEndpoint: 'https://boxter.club/broadcasting/auth',
  auth: {
    headers: {
      Authorization: "Bearer 239|2jmyhTXbaqMCIexxFAOHlpsSW6RsSqrwfCzoANCa"
    }
  }
});


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
