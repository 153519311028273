<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
//import axios from "axios";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
  mounted() {
    window.Echo.private("carservice.1").listen("AppointmentAccepted", (e) => {
      console.log(e);
    });
    /*axios.post("https://boxter.club/api/v1/user/auth/password", {
      phone: "+79876543210",
      password: "123123",
    })
    .then(response => {
      console.log(response)
    })
    .catch(error => {
      console.log(error)
    });*/
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
